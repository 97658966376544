import "./App.css";
import Router from "./Router";
import { AuthContextProvider } from "./context/Auth.context.js";

function App() {
  return (
    <AuthContextProvider>
      <div className="App">
        <Router />
      </div>
    </AuthContextProvider>
  );
}

export default App;
