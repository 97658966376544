import React, { Suspense } from "react";
import { BrowserRouter as Routers, Routes, Route } from "react-router-dom";
import AuthContext from "./context/Auth.context";
import { useContext  } from "react";
import Cookies  from "js-cookie";
import Loading from "./components/Loading";

//routes
const NotFound = React.lazy(() => import("./components/NotFound"));
const Homepage = React.lazy(() => import("./components/Homepage"));
const Header = React.lazy(() => import("./components/Header"));
const WhoWeAre = React.lazy(() => import("./components/WhoWeAre"));
const Events = React.lazy(() => import("./components/Events"));
const SingleEvent = React.lazy(() => import("./components/SingleEvent"));
const Footer = React.lazy(() => import("./components/Footer"));
const Values = React.lazy(() => import("./components/Values"));
const About = React.lazy(() => import("./components/About"));
const Courses = React.lazy(() => import("./components/Courses"));
const SingleCourse = React.lazy(() => import("./components/SingleCourse"));
const ContactUs = React.lazy(() => import("./components/ContactUs"));
const Test = React.lazy(() => import("./components/Test"));
const Blog = React.lazy(() => import("./components/Blog"));
const SingleBlog = React.lazy(() => import("./components/SingleBlog"));
const Careers = React.lazy(() => import("./components/Careers"));
const Alumni = React.lazy(() => import("./components/Alumni"));
const AlumniSingle = React.lazy(() => import("./components/AlumniSingle"));
const Student = React.lazy(() => import("./components/Student"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const SearchResults = React.lazy(() => import("./components/SearchResults"));
const Search = React.lazy(() => import("./components/Search"));

//admin routes
const AppLayout = React.lazy(() => import("./adminComponents/layout/AppLayout"));
const AdminLogin = React.lazy(() => import("./adminComponents/Login"));
const AdminHome = React.lazy(() => import("./adminComponents/AdminHome"));
const Logout = React.lazy(() => import("./adminComponents/logout"));

// import Cookies from "js-cookie";
const EditableHomePage  = React.lazy(() => import("./adminComponents/EditablePages/HomePage"));
const EditableCategories  = React.lazy(() => import("./adminComponents/EditableTables/Categories"));
const EditableCourses  = React.lazy(() => import("./adminComponents/EditableTables/Courses"));
const EditableTestimonials  = React.lazy(() => import("./adminComponents/EditableTables/Testimonials"));
const EditableNews  = React.lazy(() => import("./adminComponents/EditableTables/News"));
const EditableEvents  = React.lazy(() => import("./adminComponents/EditableTables/Events"));
const EditableWalkthroughVideos  = React.lazy(() => import("./adminComponents/EditableTables/WalkThroughVideos"));
const EditableConnectWithImages  = React.lazy(() => import("./adminComponents/EditableTables/ConnectWithImages"));
const EditableAlumni  = React.lazy(() => import("./adminComponents/EditableTables/Alumni"));
const EditableBlogs  = React.lazy(() => import("./adminComponents/EditableTables/Blogs"));
const EditableCareers  = React.lazy(() => import("./adminComponents/EditableTables/Careers"));
const EditableHistory  = React.lazy(() => import("./adminComponents/EditableTables/History"));
const EditableStaff  = React.lazy(() => import("./adminComponents/EditableTables/Staff"));
const EditableWhoWeAre  = React.lazy(() => import("./adminComponents/EditableTables/WhoWeAre"));
const AlumniEventComponent  = React.lazy(() => import("./adminComponents/EditableTables/AlumniEvents"));
const EditableAlumniAssociateMembers  = React.lazy(() => import("./adminComponents/EditableTables/AlumniAssociateMembers"));
const EditableCommitteeMembers  = React.lazy(() => import("./adminComponents/EditableTables/CommitteeMembers"));
const EditableCommunityMembers  = React.lazy(() => import("./adminComponents/EditableTables/CommunityMembers"));
const Logs  = React.lazy(() => import("./adminComponents/Logs"));


export default function Router() {
  const admin = Cookies.get("admin");
  //const admin = true;

  const { adminLogged } = useContext(AuthContext);
  console.log(admin,adminLogged)
  return (
    <div>
      <Routers>
        <Suspense fallback={<Loading />}>
          {admin ? (
            <>
            <Routes>
              {adminLogged ? (
                <>
                  <Route path="/" element={<AppLayout />}>
                    <Route index element={<AdminHome />} />
                    <Route path="/editable-homepage" element={<EditableHomePage />} />
                    <Route path="/editable-courses" element={<EditableCourses />} />
                    <Route path="/editable-categories" element={<EditableCategories />} />
                    <Route path="/editable-testimonials" element={<EditableTestimonials />} />
                    <Route path="/editable-news" element={<EditableNews />} />
                    <Route path="/editable-events" element={<EditableEvents />} />
                    <Route path="/editable-walkthrough-videos" element={<EditableWalkthroughVideos />} />
                    <Route path="/editable-Connect-with-images" element={<EditableConnectWithImages />} />
                    <Route path="/editable-alumni" element={<EditableAlumni />} />
                    <Route path="/editable-blogs" element={<EditableBlogs />} />
                    <Route path="/editable-alumni-events" element={<AlumniEventComponent />} />
                    <Route path="/editable-careers" element={<EditableCareers />} />
                    <Route path="/editable-history" element={<EditableHistory />} />
                    <Route path="/editable-staff" element={<EditableStaff />} />
                    <Route path="/editable-who-we-are" element={<EditableWhoWeAre />} />
                    <Route path="/editable-alumni-associate-members" element={<EditableAlumniAssociateMembers />} />
                    <Route path="/editable-committee-members" element={<EditableCommitteeMembers />} />
                    <Route path="/editable-community-members" element={<EditableCommunityMembers />} />
                    <Route path="/logs" element={<Logs />} />
                    <Route path="/logout" element={<Logout />} />
                  </Route>
                </>
              ) : (
                <>
                  <Route path="*" element={<AdminLogin />} />
                  <Route path="/" element={<AdminLogin />} />
                </>
              )}
            </Routes>
            </>
          ) : (
            <>
              <Header />
              <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route exact path="/notfound" element={<NotFound />} />
                <Route exact path="/whoweare" element={<WhoWeAre />} />
                <Route exact path="/events" element={<Events />} />
                <Route exact path="/events/single/:id" element={<SingleEvent />} />
                <Route exact path="/values" element={<Values />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/courses" element={<Courses />} />
                <Route exact path="/course/single/:id" element={<SingleCourse />} />
                <Route exact path="/contactus" element={<ContactUs />} />
                <Route exact path="/test" element={<Test />} />
                <Route exact path="/blogs" element={<Blog />} />
                <Route exact path="/blog/single/:id" element={<SingleBlog />} />
                <Route exact path="/careers" element={<Careers />} />
                <Route exact path="/alumni" element={<Alumni />} />
                <Route exact path="/alumni/single/:id" element={<AlumniSingle />} />
                <Route exact path="/student" element={<Student />} />
                <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route exact path="/admin" element={<AdminLogin />} />
                <Route exact path="/search/:id" element={<SearchResults />} />
                <Route exact path="/search" element={<Search />} />
              </Routes>
              <Footer />
            </>
          )}
        </Suspense>
      </Routers>
    </div>
  );
}