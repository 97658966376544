import { createContext, useEffect, useState } from "react";
import { Auth } from "../services/AuthServices";
import Cookies from "js-cookie";

const AuthContext = createContext();

function AuthContextProvider(props) {

  const [Token, setToken] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [adminLogged, setadminLogged] = useState(false);

  const checkToken = async ()=>{
    // let data = await Auth(Cookies.get("adminLogged"));
    // if(data?.status == 200){
    if (Cookies.get("adminLogged") !== undefined) {
      setadminLogged(true);
    } else {
      setadminLogged(false);
    }
    console.log("data", Cookies.get("adminLogged"));
  }

  useEffect(() => {

    checkToken();
    setToken(Cookies.get("adminLogged"));
    setUserRole("admin");
    setadminLogged(true);

    if (Cookies.get("adminLogged") === undefined) {
      Cookies.remove("adminLogged", { path: "" });
      setToken(Cookies.get("adminLogged"));
      setUserRole("");
      setadminLogged(false);
    }
    
  }, []);


  return (
    <AuthContext.Provider value={{ Token, userRole , adminLogged}}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
