import React from 'react'
import { BeatLoader } from 'react-spinners'

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100" style={{ background: "#1c1b3b"}}>
        <BeatLoader color="#D3072C" size={30} />
    </div>
  )
}

export default Loading